import React, { useContext, useState, useEffect } from 'react'
import { SettingsContext } from '../../../components/authorization/GetAccessRights';
// import { withPageState } from '../../../components/authorization/PageState'

import { useParams } from "react-router-dom"
import { useQuery, useMutation } from '@apollo/client'

// import { getLoggedInUser } from "../../../components/authorization/Utilities"

import toast from 'react-hot-toast'

import LoadingSpinner from '../../../components/progress/Spinner';
import LinkButton from "../../../components/button/Button"
import { Alert, Box, Button, Divider, FormControlLabel, FormGroup, Grid, Stack, TextField, Typography } from "@mui/material"

import { formatGQLDate, removeElement, addUniqueElement } from '../../../components/utilities/Formatters'
import { initializeForm, handleFormDataChange } from '../../../components/utilities/Events'
import { setErrorFieldState } from '../../../components/utilities/Validation'

import { GET_ONE_USER } from '../../../Graphql/Users/QueryUser'
import { UPDATE_USER_BY_ADMIN, DELETE_USER } from '../../../Graphql/Users/MutateUser'

import Checkbox from '@mui/material/Checkbox';

import Dropdown, { DropDownTypes } from "../../../components/autocomplete/Autocomplete"
import PronounDropdown from "../../profile/partials/PronounDropdown";

import { styled } from "@mui/material/styles";
import { EMAIL_INVITE } from '../../../Graphql/Email/MutateEmail';

// const Overlay = styled(Box)(({ theme }) => ({
//   input: {
//     background: "rgb(232, 241, 250)"
//   }
// });

const UserDetail = () => {
  // const adminPage = "admin_users"
  const settings: any = useContext(SettingsContext)
  // const loggedInUserId = getLoggedInUser(settings).id || null
  const academicYearSettings = settings.academicYearSettings

  var initFormData = {
    ucla_id: "",
    crm_id: "",
    first_name: "",
    last_name: "",
    scholarship_type: "",
    user_committee: "",
    major_degree: "",
    minor_degree: "",
    graduation_year: "",
    birthday: "",
    pronoun: "", 
    pronoun_other: "", 
    phone: "", 
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    postal_code: "",
    country: "",
    permissions: "",
    email: "",
    preferred_email: ""
  }

  const initErrors: any = {
    ucla_id: false,
    crm_id: false,
    first_name: false,
    last_name: false,
    email: false,
    birthday: false,
    phone: false, 
  }

  const other_pronoun_option = "Different or multiple sets of pronouns"

  const params = useParams()
  const id: string = params.id ? params.id : ''
  
  const [ formData, setFormData ] = useState(initFormData)
  const [ errorFields, setErrorFields ] = useState(initErrors)

  const { loading: loadingUser, error: errorUser, data: dataUser } = useQuery(GET_ONE_USER,  { variables: { id: id, academic_year: academicYearSettings.academic_year }, fetchPolicy: "network-only"})

  const [ updateUser, { error: errorUpdateUser, data: dataUpdateUser } ] = useMutation(UPDATE_USER_BY_ADMIN)
  const [ deleteUser, { error: errorDeleteUser, data: dataDeleteUser } ] = useMutation(DELETE_USER)

  const [ emailInvite, { error: errorEmailInvite, data: dataEmailInvite } ] = useMutation(EMAIL_INVITE)

  const [ user, setUser ] = useState<any>({})
  const [ userParticipationStats, setUserParticipationStats ] = useState([])
  const [ userPermissions, setUserPermissions ] = useState([])
  const [ permissionsList, setPermissionsList ] = useState([])
  const [ scholarshipTypes, setScholarshipTypes ] = useState([])
  const [ pronouns, setPronouns ] = useState([])
  const [ countries, setCountries ] = useState([])
  const [ userCommittees, setUserCommittees ] = useState([])
  const [ majors, setMajors ] = useState([])
  
  const handleUpdateUserClick = () => { 
    // formData Should have all the updated data even if other refs to formData onChange does not show it right away
    console.debug('START handleUpdateUserClick()')
    console.debug('formData')
    console.debug(formData)
    const variables = {
      id: id,
      ucla_id: formData.ucla_id,
      crm_id: formData.crm_id,
      first_name: formData.first_name,
      last_name: formData.last_name,
      major_degree: formData.major_degree,
      minor_degree: formData.minor_degree,
      scholarship_type: formData.scholarship_type,
      user_committee: formData.user_committee,
      birthday: formatGQLDate({gQLDate: formData.birthday, format: "YYYY-MM-DD", formatDirection: "w"}),
      pronoun: formData.pronoun, 
      pronoun_other: formData.pronoun === other_pronoun_option ?  formData.pronoun_other : null, 
      phone: formData.phone, 
      address_1: formData.address_1,
      address_2: formData.address_2,
      city: formData.city,
      state: formData.state,
      postal_code: formData.postal_code,
      country: formData.country,
      permissions: formData.permissions,
      email: formData.email,
      preferred_email: formData.preferred_email,
      graduation_year: formData.graduation_year ? formData.graduation_year : null
    }
    console.log('variables: ', variables)
    
    updateUser({
      variables: variables
    })

    console.debug('END handleUpdateProfileClick()')
  }

  const handleDeleteUserClick = () => { 
    console.debug('handleDeleteUserClicked')

    if (window.confirm("Are you sure you want to permanently delete user:" + user.full_name + "?")) {
      deleteUser({variables: {id: id}})
    }
  }

  const handleResendInvitation = () => {
    
    const userData = dataUser.getOneUser
    const variables = {
      email: userData.email,
      firstName: userData.first_name,
      lastName: userData.last_name,
      scholarshipType: userData.scholarship_type,
      uid: userData.ucla_id

    }
    console.debug("handleResendInvitation: ", userData, variables)

    emailInvite({variables: variables})
  }

  useEffect(() => {
    if (errorEmailInvite) {
      toast.error('There was an error attempting to Resend the Invitation: ' + errorEmailInvite)
      console.error('Error Sending Invite: ', errorEmailInvite)
    }

    if (dataEmailInvite) {
      const emailInviteResponse = dataEmailInvite.emailInvite 
      const success = emailInviteResponse.successful
      const message = emailInviteResponse.message

      console.debug('Email Invite Response: ', success, message);
      if (success) {
        toast.success("Email Invite Resent Sucessful: " + message)
      } 
      if (success === false) {
        toast.error("Email Invite Resent Unsuccessful: " + message)
      } 

    }
  }, [errorEmailInvite, dataEmailInvite ])



  useEffect(() => {           
    if (!errorUser && dataUser) {
      console.log("dataUser: ", dataUser)
      setUser(dataUser.getOneUser);
      setUserPermissions(dataUser.getOneUser.permissions ? dataUser.getOneUser.permissions.split(",") : [])
      setPronouns(dataUser.getPronouns.pronouns)
      setScholarshipTypes(dataUser.getScholarshipTypes.scholarship_types)
      setCountries(dataUser.getCountries.countries)
      setUserCommittees(dataUser.getUserCommittees.user_committees)
      setMajors(dataUser.getMajors)
      setPermissionsList(dataUser.getPermissions.permissions)
      setUserParticipationStats(dataUser.getUserParticipationStats)
    }
  }, [id, errorUser, dataUser])

  useEffect(() => {
    initializeForm(setFormData, user)

  }, [setFormData, user])

  useEffect(() => {        
    let toastMsg: string = ''

    if (errorUpdateUser) {
      console.debug('errorUpdateUser', errorUpdateUser)
      toastMsg = "Error Attempting to Update User: "
      toast.error(toastMsg)
        
    } else if (dataUpdateUser) {
        if (dataUpdateUser.updateUserAdmin.successful) {
            // window.location.href = `/admin/users`
            toastMsg = "User Updated Successfully!";
            toast.success(toastMsg);
        } else {
            console.debug(dataUpdateUser.updateUserAdmin)
            toastMsg = dataUpdateUser.updateUserAdmin.message
            toast.error(toastMsg)

            setErrorFields(setErrorFieldState(dataUpdateUser.updateUserAdmin.error_fields))
        }
    } 
    if (toastMsg) {
        console.log(`Toast Message: ${toastMsg}`)
    }

}, [id, errorUpdateUser, dataUpdateUser])

  useEffect(() => {        
    let toastMsg: string = ''

    if (errorDeleteUser) {
      console.debug('errorCreateUser')
      console.debug(errorDeleteUser)
      toastMsg = "Error Attempting to Delete User"
      toast.error(toastMsg)
        
    } else if (dataDeleteUser) {
        if (dataDeleteUser.deleteUser.successful) {
            window.location.href = `/admin/users`
            // toastMsg = "User Created Successfully!";
            // toast.success(toastMsg);
        } else {
            toastMsg = dataDeleteUser.deleteUser.message
            toast.error(toastMsg)
        }
    } 
    if (toastMsg) {
        console.log(`Toast Message: ${toastMsg}`)
    }

}, [id, errorDeleteUser, dataDeleteUser])

	return (
		<>
      { loadingUser && 
        <LoadingSpinner/>
      }
      <LinkButton to="/admin/users" type="back" variant="outlined">
        Return to User Management
      </LinkButton>
      <Box my={3}>
        <Typography variant="h2">
          User Management Center
        </Typography>
        <Typography paragraph>
          View and modify user account and demographic information
        </Typography>
      </Box>
      <Divider />
      <Box my={3}>
        <Typography variant="h2">
          {user.first_name} {user.last_name} - {user.ucla_id}
        </Typography>
        <Box my={3}>
          {/* <Typography variant="h4">
            Last Login: {user.last_logged_in ? formatGQLDate({gQLDate: user.last_logged_in, format: "MM/DD/YYYY"}) : "Hasn't logged in yet"}
          </Typography> */}
          <Typography variant="h4">Status: {user.last_logged_in ? 'Active' : 'Inactive'}</Typography>
          <Typography variant="h4">ASC DB ID: {user.id}</Typography>
          <br/>
          <Typography variant="h4">Scholarship Name: {user.scholarship_name}</Typography>
        </Box>
        <Button variant="contained" onClick={() => { handleResendInvitation() }}>Resend Invitation</Button>
      </Box>
      <Divider />
      
      <GeneralInfo setFormData={setFormData} formData={formData} errorFields={errorFields} scholarshipTypes={scholarshipTypes} userCommittees={userCommittees} />
      <PersonalInfo setFormData={setFormData} formData={formData} errorFields={errorFields} pronouns={pronouns} countries={countries} other_pronoun_option={other_pronoun_option} />
      <AcademicInfo setFormData={setFormData} formData={formData} majors={majors} />
      <UserPermissions setFormData={setFormData} formData={formData} permissionsList={permissionsList} userPermissions={userPermissions}/>

      <Box mt={5}>
        <Button variant="contained"
          onClick={() => { 
            handleUpdateUserClick()
          }}>Save Changes</Button>
      </Box>
      {/* START TEST BLOCK */}
      {/* <Divider sx={{ mt: 6 }} /> */}
      {/* <DetailList user={user} participationStats={userParticipationStats}/> */}
      <Stack spacing={3} sx={{ maxWidth: 360, mt: 3 }}>
        {/*<div>{JSON.stringify(settings)}</div>*/}
        {settings.accessRights.admin_permanent &&
          <Button variant="outlined" onClick={() => { handleDeleteUserClick()}}>Delete User</Button>
        }

        {/* <Button variant="outlined"
          onClick={() => window.location.href = "/profile/" + id }
          >TEST - Student Update Profile</Button>
        <Button variant="outlined"
          onClick={() => window.location.href = "/participation/" + id }
          >TEST - Student Participation</Button>
        <Button variant="outlined"
          onClick={() => window.location.href = "/bank/" + id }
          >TEST - Student Notes/Test Bank</Button> */}
      </Stack>
      {/* END TEST BLOCK */}
		</>
	)
}

const GeneralInfo = ({setFormData, formData, errorFields, scholarshipTypes, userCommittees}:any) => {
  return (
    <>
      <Box my={3}>
        <Typography variant="h2">
          Essential Information
        </Typography>
        <Divider />
        <Alert 
          severity="warning" 
          sx={{ 
            maxWidth: 660,
            fontSize: 16,
            lineHeight: 1.5,
            mb: 3
          }}
        >
          Please use caution when editing the settings for a user as this will be immediately reflected on their account and can additionally affect their ability to login to the website.
        </Alert>
      </Box>
      <Stack 
        spacing={3}
        sx={{ 
          maxWidth: 300, 
          mt: 3,
        }}
      >
        <TextField
          required
          error={errorFields.email || false}
          id="email"
          label="Login Email"
          value={formData.email || ''}
          onChange={(event) => {
            handleFormDataChange(setFormData, formData, "email", event.target.value)
          }}
          // type="email"
        />
        <TextField
          required
          error={errorFields.crm_id || false}
          id="crm_id"
          label="CRM ID"
          value={formData.crm_id || ''}
          onChange={(event) => {
            handleFormDataChange(setFormData, formData, "crm_id", event.target.value)
          }}
          // InputProps={{
          //   readOnly: true,
          // }}
        />
      </Stack>
      <Box my={3}>
        <Typography variant="h2">
          General Information
        </Typography>
      </Box>
      <Divider />
      <Stack 
        spacing={3}
        sx={{ 
          maxWidth: 300, 
          mt: 3,
        }}
      >
        <Dropdown type={DropDownTypes.Scholarship} options={scholarshipTypes} setFormData={setFormData} formData={formData} />
        <Dropdown type={DropDownTypes.Committee} options={userCommittees} setFormData={setFormData} formData={formData} />
        <Stack direction="row" spacing={2}>
          <TextField
            required
            error={errorFields.first_name || false}
            id="first_name"
            label="First Name"
            value={formData.first_name || ''}
            onChange={(event) => {
              handleFormDataChange(setFormData, formData, "first_name", event.target.value)
            }}
          />
          <TextField
            required
            error={errorFields.last_name || false}
            id="last_name"
            label="Last Name"
            value={formData.last_name || ''}
            onChange={(event) => {
              handleFormDataChange(setFormData, formData, "last_name", event.target.value)
            }}
          />
        </Stack>
        <TextField
          required
          error={errorFields.ucla_id || false}
          id="ucla_id"
          label="UCLA ID"
          value={formData.ucla_id || ''}
          onChange={(event) => {
            handleFormDataChange(setFormData, formData, "ucla_id", event.target.value)
          }}
          // Add Validation Length = 9
        />
      </Stack>
    </>
  )
}

const PersonalInfo = ({setFormData, formData, errorFields, pronouns, countries, other_pronoun_option}:any) => {
  
  return (
    <>
      <Box mt={5} mb={3}>
        <Typography variant="h2">
          Personal Information
        </Typography>
      </Box>
      <Divider />
      <Stack 
        spacing={3}
        sx={{ 
          maxWidth: 300, 
          mt: 3,
        }}
      >
        <TextField
          id="preferred_email"
          label="Preferred Email"
          value={formData.preferred_email || ''}
          onChange={(event) => {
            handleFormDataChange(setFormData, formData, "preferred_email", event.target.value)
          }}
          // type="email"
        />
        <TextField
          required
          error={errorFields.phone || false}
          id="phone"
          label="Phone Number"
          value={formData.phone || ''}
          onChange={(event) => {
            handleFormDataChange(setFormData, formData, "phone", event.target.value)
          }}
        />
        <TextField
          required
          error={errorFields.birthday || false}
          id="birthday"
          label="Birthday"
          type="date"
           value={formData.birthday ? formatGQLDate({gQLDate: formData.birthday, format: "YYYY-MM-DD"}) : ''}
          onChange={(event) => { handleFormDataChange(setFormData, formData, "birthday", event.target.value) }}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <PronounDropdown options={pronouns} setFormData={setFormData} formData={formData} other_pronoun_option={other_pronoun_option}/>
        {/* <Dropdown type={DropDownTypes.Pronoun} options={pronouns} setFormData={setFormData} formData={formData} /> */}

        <TextField
          value={formData.address_1 || ''}
          id="address_1"
          label="Address"
          onChange={(event) => {
            handleFormDataChange(setFormData, formData, "address_1", event.target.value)
          }}
        />
        <TextField
          value={formData.address_2 || ''}
          id="address_2"
          label="Address Line 2 (optional)"
          onChange={(event) => {
            handleFormDataChange(setFormData, formData, "address_2", event.target.value)
          }}
        />
        <TextField
          value={formData.city || ''}
          id="city"
          label="City"
          onChange={(event) => {
            handleFormDataChange(setFormData, formData, "city", event.target.value)
          }}
        />
        <Stack direction="row" spacing={2}>
          <TextField
            value={formData.state || ''}
            id="state"
            label="State"
            onChange={(event) => {
              handleFormDataChange(setFormData, formData, "state", event.target.value)
            }}
          />
          <TextField
            value={formData.postal_code || ''}
            id="postal_code"
            label="Zip/Postal Code"
            onChange={(event) => {
              handleFormDataChange(setFormData, formData, "postal_code", event.target.value)
            }}
          />
        </Stack>
        <Dropdown type={DropDownTypes.Country} options={countries} setFormData={setFormData} formData={formData} />
      </Stack>
    </>
  )
}

const AcademicInfo = ({setFormData, formData, majors}:any) => {
  // getYearList()
  return (
    <>
      <Box mt={5} mb={3}>
        <Typography variant="h2">
          Academic Information
        </Typography>
      </Box>
      <Divider />
      <Stack 
        spacing={3}
        sx={{ 
          maxWidth: 300, 
          mt: 3,
        }}
      >
        <Dropdown type={DropDownTypes.GraduationYear} options={getYearList()} setFormData={setFormData} formData={formData} />
        <Dropdown type={DropDownTypes.Major} options={majors} setFormData={setFormData} formData={formData} />
        <Dropdown type={DropDownTypes.Minor} options={majors} setFormData={setFormData} formData={formData} />
      </Stack>
    </>
  )
}

const UserPermissions = ({setFormData, formData, permissionsList, userPermissions}: any) => {
  
  const handleSelectedPermissions = (event: any) => {
    // console.log('selected permission')
    const target = event.target as HTMLInputElement;
    
    // handleFormDataChange(setFormData, formData, "permissions", event.target.value)
    let permissionData = formData.permissions ? formData.permissions.split(',') : []
    console.log('formData.permissions: ' + permissionData)
    if (target.checked) {
      console.log('checked: ' + target.value)
      addUniqueElement(permissionData, target.value)
    } else {
      console.log('un-checked: ' + target.value)
      removeElement(permissionData, target.value)
    }
    handleFormDataChange(setFormData, formData, "permissions", permissionData.join(','))
  }

  return (
    <>
      <Box mt={5} mb={3}>
        <Typography variant="h2">
          User Permissions
        </Typography>
      </Box>
      <Divider />
      <Box my={2}>
        <Typography>
          For users to access any aspect of the control panel, they must be granted the Access Control Panel permission.
        </Typography>
      </Box>
      <FormGroup>
      <Typography variant='h4'>Feature Access</Typography>
        <Grid container item spacing={2} sx={{ maxWidth: 660, m: 0, mb: 3 }}>
          
        {permissionsList.map((permission: any, index: number) => {
          let checked = false
          if (userPermissions.includes(permission.value)) {
            checked = true
          }
          if (permission.value.includes("user")) {
          return (
            <React.Fragment key={`pUser${index}`}>
              <Grid xs={12} item>
                <FormControlLabel 
                  control={
                    <Checkbox defaultChecked={checked} />
                  } 
                  label={permission.label} 
                  value={permission.value}
                  onChange={(event) => { 
                    handleSelectedPermissions(event)
                }}/>
              </Grid>
            </React.Fragment>
          )
        }
        })}
        </Grid>

        <Typography variant='h4'>Admin Access Permissions</Typography>
        <Grid container item spacing={2} sx={{ maxWidth: 660, m: 0 }}>
          
        {permissionsList.map((permission: any, index: number) => {
          let checked = false
          if (userPermissions.includes(permission.value)) {
            checked = true
          }
          if (permission.value.includes("admin")) {
          return (
            <React.Fragment key={`pAdmin${index}`}>
              <Grid xs={12} md={6} item>
                <FormControlLabel 
                  control={
                    <Checkbox defaultChecked={checked} />
                  } 
                  label={permission.label} 
                  value={permission.value}
                  onChange={(event) => { 
                    handleSelectedPermissions(event)
                }}/>
              </Grid>
            </React.Fragment>
          )
        }
        })}
        </Grid>
      </FormGroup>
    </>
  )
}

function getYearList(add=4) {
  const todaysDate = new Date();
  const currentYear: number = todaysDate.getFullYear();
  var list: Array<any> = []
  var count = 1
  list.push({
    label: `${currentYear-1}`,
    type: 0
  })
  for (var i=currentYear; i<=currentYear+add; i++) {
    list.push({
      label: `${i}`,
      type: count
    })
    count++
  }
  return list
}

// const DetailList = ({user, participationStats}:any) => {
//   return (
//     <>
//       <h1>User Detail</h1>
//       <div>UCLA ID: {user.ucla_id}</div>
//       {/* <div>Permissions: {user.permissions}</div> */}
//       <div>First Name: {user.first_name}</div>
//       <div>Last Name: {user.last_name}</div>
//       <div>email: {user.email}</div>
//       <div>Scholarship Type: {user.scholarship_type}</div>
//       <div>Scholarship Name: {user.scholarship_name}</div>
//       <div>User Committee: {user.user_committee}</div>
//       <div>Major: {user.major_degree}</div>
//       <div>Minor: {user.minor_degree}</div>
//       <div>Birthday: {formatGQLDate({gQLDate: user.birthday, format: "MM/DD/YYYY"})}</div>
//       <div>Pronoun: {user.pronoun}</div>
//       <div>Pronoun Other: {user.pronoun_other}</div>
//       <div>Address 1: {user.address_1}</div>
//       <div>Address 2: {user.address_2}</div>
//       <div>City: {user.city}</div>
//       <div>State: {user.state}</div>
//       <div>Postal Code: {user.postal_code}</div>
//       <div>Country: {user.country}</div>
//       <div>financial_aid_app_adm_trm: {user.financial_aid_app_adm_trm}</div>
//       <div>financial_aid_app_lvl_entry: {user.financial_aid_app_lvl_entry}</div>
//       <div>total hours pending: {participationStats.participation_total_hours_pending}</div>
//       <div>total hours denied: {participationStats.participation_total_hours_denied}</div>
//       <div>total hours approved: {participationStats.participation_total_hours_approved}</div>
//       <div>total events pending: {participationStats.participation_total_events_pending}</div>
//       <div>total events denied: {participationStats.participation_total_events_denied}</div>
//       <div>total events approved: {participationStats.participation_total_events_approved}</div>
//       <div>Last Login Date: {user.last_logged_in ? formatGQLDate({gQLDate: user.last_logged_in}) : "Hasn't logged in yet"}</div>
//       <div>Updated On: {formatGQLDate({gQLDate: user.updated_on})}</div>
//       <div>Created On: {formatGQLDate({gQLDate: user.created_on})}</div>
//     </>
//   )
// }

// export default withPageState(UserDetail, { pageSection: "admin", adminAccess: "admin_users" })
export default UserDetail