
import React, { useContext, useEffect, useState } from "react"
import { SettingsContext } from '../../components/authorization/GetAccessRights'
import { localStorageLoggedInUser, getLoggedInUser } from "../../components/authorization/Utilities"

import { useParams } from "react-router-dom"
import { useQuery, useMutation } from '@apollo/client'

// import { withPageState } from '../../components/authorization/PageState'

import toast from 'react-hot-toast'

import { getUserId } from '../../components/authorization/Utilities'

import { formatGQLDate } from '../../components/utilities/Formatters';

import { Button, Checkbox, Stack, TextField, Typography } from "@mui/material";

import LoadingSpinner from "../../components/progress/Spinner";
import LayoutContainer from "../../components/container/Container";
import Dropdown, { DropDownTypes } from "../../components/autocomplete/Autocomplete"
import { initializeForm, handleFormDataChange } from '../../components/utilities/Events'
import { setErrorFieldState } from '../../components/utilities/Validation'

import { GET_ONE_USER_NON_ADMIN } from '../../Graphql/Users/QueryUser'
import { UPDATE_USER_BY_USER } from '../../Graphql/Users/MutateUser'
import PronounDropdown from "./partials/PronounDropdown";

const PersonalProfile = () => {
  // Object keys need to be the same as what's returned from the get request
  var initFormData = {
    major_degree: "",
    minor_degree: "",
    graduation_year: null,
    birthday: "",
    pronoun: "", 
    pronoun_other: "", 
    phone: "", 
    preferred_email: "", 
    address_1: "",
    address_2: "",
    city: "",
    state: "",
    postal_code: "",
    country: "",
    confirm_accurate_data: false
  }

  const initErrors: any = {
    birthday: false,
    phone: false, 
    confirm_accurate_data: false,
    
  }

  const other_pronoun_option = "Different or multiple sets of pronouns"

  const params = useParams()
  const settings: any = useContext(SettingsContext)
  // const loggedInUserId = getLoggedInUser(settings).id || null
  // const academicYearSettings = settings.academicYearSettings
  const id = getUserId(settings.accessRights, params.id)

  const firstTime = !settings.profileFilled; // profileFilled = required First Time fields filled out.  first time means required profile fields were not filled out

  const { loading: loadingUser, error: errorUser, data: dataUserAndLists } = useQuery(GET_ONE_USER_NON_ADMIN,  { variables: { id: id }, fetchPolicy: "network-only"})
  const [ updateUser, { error: errorUpdateUser, data: dataUpdateUser } ] = useMutation(UPDATE_USER_BY_USER)
  
  const [ formData, setFormData ] = useState(initFormData)
  const [ errorFields, setErrorFields ] = useState(initErrors)

  const [ user, setUser ] = useState<any>({})
  const [ pronouns, setPronouns ] = useState(Array<any>)
  const [ countries, setCountries ] = useState(Array<any>)
  const [ degrees, setDegrees ] = useState(Array<any>)

  const handleUpdateProfileClick = () => { 
    // formData Should have all the updated data even if other refs to formData onChange does not show it right away
    console.debug('START handleUpdateProfileClick()')
    console.debug('formData', formData)
    // console.debug(`id: ${id}`)
    // console.debug(`major: ${formData.major_degree}`)
    // console.debug(`minor: ${formData.minor_degree}`)
    // console.debug(`birthday: ${formatGQLDate({gQLDate: formData.birthday, format: "YYYY-MM-DD"})}`)
    
    let variables: any = {
      id: id,
      major_degree: formData.major_degree,
      minor_degree: formData.minor_degree,
      birthday: formatGQLDate({gQLDate: formData.birthday, format: "YYYY-MM-DD", formatDirection: "w"}),
      pronoun: formData.pronoun, 
      pronoun_other: formData.pronoun === other_pronoun_option ?  formData.pronoun_other : null,
      phone: formData.phone, 
      address_1: formData.address_1,
      address_2: formData.address_2,
      city: formData.city,
      state: formData.state,
      postal_code: formData.postal_code,
      country: formData.country,
      preferred_email: formData.preferred_email,
    }
    
    if (firstTime === true) {
      variables["confirm_accurate_data"] = formData.confirm_accurate_data
    }

    console.log('updateUser firstTime:', firstTime, variables)
    
    updateUser({
      variables: variables
    })

    console.debug('END handleUpdateProfileClick()')
  }

  useEffect(() => {
    initializeForm(setFormData, user)
  }, [setFormData, user ])

  useEffect(() => {       
    if (!errorUser && dataUserAndLists) {
      console.debug('dataUserAndLists: ')
      console.debug(dataUserAndLists)

      setUser(dataUserAndLists.getOneUser)
      // Set Lists  
      setPronouns(dataUserAndLists.getPronouns.pronouns)
      setCountries(dataUserAndLists.getCountries.countries)
      setDegrees(dataUserAndLists.getMajors)
      
    }
    
    if (errorUser) {
      console.debug('errorUser')
      console.debug(errorUser)
    }
  }, [id, errorUser, dataUserAndLists ])

  useEffect(() => {     
    let toastMsg: string = ''

    if (errorUpdateUser) {
      console.debug('errorUpdateUser')
      console.debug(errorUpdateUser)
      toastMsg = "Error attempting to Update User";
      toast.error(toastMsg)
        
    } else if (dataUpdateUser) {
        console.debug('ASC - data: ', dataUpdateUser)
        if (dataUpdateUser.updateUser.successful) {
          toast.success(dataUpdateUser.updateUser.message)
          
          // update localstorage for profile_filled_out
          let lsLoggedInUser = localStorageLoggedInUser()
          lsLoggedInUser.profile_filled_out = true
          localStorage.setItem("loggedInUser", JSON.stringify(lsLoggedInUser))
          window.location.href = "/participation" //Need to change to user summary page not admin
          
        } else {
            toastMsg = 'Please fill out the required fields highlighted in Red'
            console.log(dataUpdateUser.updateUser.message)
            toast.error(toastMsg)

            setErrorFields(setErrorFieldState(dataUpdateUser.updateUser.error_fields))
        }
    } 
    if (toastMsg) {
        console.log(`Toast Message: ${toastMsg}`)
    }
  }, [id, errorUpdateUser, dataUpdateUser])

  // console.debug("profile_filled_out", settings.profileFilled, settings)

	return (
		<>
      { loadingUser && 
        <LoadingSpinner/>
      }
      <LayoutContainer>
        <IntroHeader firstTime={firstTime} user={user}/>
        <Stack spacing={3} sx={{ maxWidth: 300, mt: 3 }}>
        <TextField
            disabled
            value={user.ucla_id || ''}
            id="uid"
            label="UID"
          />

          <Dropdown type={DropDownTypes.Major} options={degrees} setFormData={setFormData} formData={formData} />
          <Dropdown type={DropDownTypes.Minor} options={degrees} setFormData={setFormData} formData={formData} />

          <TextField
            disabled
            value={formData.graduation_year || ''}
            id="gradYear"
            label="graduation Year"
            onChange={(event) => {
              handleFormDataChange(setFormData, formData, "graduation_year", event.target.value)
            }}
          />
          <TextField
            required
            error={errorFields.birthday || false}
            value={formData.birthday ? formatGQLDate({gQLDate: formData.birthday, format: "YYYY-MM-DD"}) : ''}
            id="birthday"
            label="Birthday"
            type="date"
            onChange={(event) => { handleFormDataChange(setFormData, formData, "birthday", event.target.value) }}
            InputLabelProps={{
              shrink: true,
            }}
          />
          <PronounDropdown options={pronouns} setFormData={setFormData} formData={formData} other_pronoun_option={other_pronoun_option}/>

          <TextField
            // required
            value={formData.preferred_email || ''}
            id="preferred_email"
            label="Preferred contact email address"
            onChange={(event) => { handleFormDataChange(setFormData, formData, "preferred_email", event.target.value) }}
          />

          <TextField
            required
            error={errorFields.phone || false}
            value={formData.phone || ''}
            id="phone"
            label="Phone Number"
            onChange={(event) => { handleFormDataChange(setFormData, formData, "phone", event.target.value) }}
          />

          { !firstTime && 
          <>
            <TextField
              value={formData.address_1 || ''}
              id="address"
              label="Address"
              onChange={(event) => { handleFormDataChange(setFormData, formData, "address_1", event.target.value) }}
            />
            <TextField
              value={formData.address_2 || ''}
              id="Address2"
              label="Address Line 2 (optional)"
              onChange={(event) => { handleFormDataChange(setFormData, formData, "address_2", event.target.value) }}
            />
            <TextField
              value={formData.city || ''}
              id="city"
              label="City"
              onChange={(event) => { handleFormDataChange(setFormData, formData, "city", event.target.value) }}
            />
            <Stack direction="row" spacing={2}>
              <TextField
                value={formData.state || ''}
                id="state"
                label="State"
                onChange={(event) => { handleFormDataChange(setFormData, formData, "state", event.target.value) }}
              />
              <TextField
                value={formData.postal_code || ''}
                id="postalCode"
                label="Zip/Postal Code"
                onChange={(event) => { handleFormDataChange(setFormData, formData, "postal_code", event.target.value) }}
              />
            </Stack>
            <Dropdown type={DropDownTypes.Country} options={countries} setFormData={setFormData} formData={formData} />
          </>
          }

        </Stack>
        { firstTime && 
          <TermsCheckbox setFormData={setFormData} formData={formData} errorFields={errorFields}/>
        }
        <Stack spacing={3} sx={{ maxWidth: 200, mt: 3 }}>
          <Button variant="contained"
            onClick={() => { handleUpdateProfileClick() }}
          >
            { firstTime ? <>Save Personal Info</> : <>Save Changes</> } 
          </Button>
        </Stack>
      </LayoutContainer>
    </>
	);
};


function TermsCheckbox ({formData, setFormData, errorFields}: any) {
  const label = { inputProps: { 'aria-label': 'Checkbox demo' } };

  return (
    <Stack direction="row" spacing={1} alignItems="start" sx={{ maxWidth: 550, mt: 3 }}>
      <Checkbox 
        checked={formData.confirm_accurate_data}
        onChange={(event) => {
          console.debug(event.target.value)
          handleFormDataChange(setFormData, formData, "confirm_accurate_data", event.target.checked)
        }}
        sx={{ color: `${errorFields.confirm_accurate_data === true ? '#ba000d' : '' }`  }}
        {...label} />
      <Typography 
        paragraph pt={0.75}
        sx={{ color: `${errorFields.confirm_accurate_data === true ? '#ba000d' : '' }`  }}
        >
        By checking this box, you confirm that all data entered on this page is accurate to the best of your knowledge. Additionally, you agree to the <a href="##">Terms and Conditions</a> of the ASC website and certify that you understand the requirements for annual scholarship renewal.
      </Typography>
    </Stack> 
  )
}

function IntroHeader ({firstTime, user}: any) {
  return (
    <>
      <Typography variant="h2" mb={3}>
        { firstTime === true ?  <>First Time Setup - Personal Info</> : <>Update Personal Info</> }
      </Typography>
      { firstTime && <Typography variant="h3" mb={3}>Hi, {user.first_name}!</Typography> }
      <Typography variant="body1" sx={{ maxWidth: 640 }}>
        { firstTime === true ?
        <>Welcome to Alumni Scholars Club, please fill out this form with your information.  Fields in gray are information we have received from financial aid.  If you see any incorrect information, please contact ASC Administrators.</>
        :
        <>It is important to make sure that the information in this form is correct at all times to ensure proper reporting to the Alumni Scholars Club Staff. <strong>Failure to maintain correct information could lead to a delay in scholarship renewal processing.</strong> Click update below to submit.</>
        }
      </Typography>
    </>
  )
}

// export default withPageState(PersonalProfile, {})
export default PersonalProfile